.loading_skeleton {
    width: 100%;
    height: auto;
    div {
        width: 100%;
        height: 50px;
        background: #242424;
        border-radius: 10px;
        margin: 15px 0;
        background-image: linear-gradient(90deg, #242424 0px, #292929 15%, #242424 30%);
        background-size: 300%;
        background-position: 100% 0;
        -webkit-animation: skeleton 2s infinite;
        -moz-animation: skeleton 2s infinite;
        -o-animation: skeleton 2s infinite;
        animation: skeleton 2s infinite;
        @media screen and (max-width: 760px) {
            height: 90px;
        }
    }
}

@-webkit-keyframes skeleton {
    from { background-position: 100% 0; }
    to { background-position: -100% 0; }
}
@-moz-keyframes skeleton {
    from { background-position: 100% 0; }
    to { background-position: -100% 0; }
}
@-o-keyframes skeleton {
    from { background-position: 100% 0; }
    to { background-position: -100% 0; }
}
@keyframes skeleton {
    from { background-position: 100% 0; }
    to { background-position: -100% 0; }
}