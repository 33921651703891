$green: #30b665;

.container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: #111;
    color: #FFF;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;

    .app_wrapper {
        width: 100%;
        max-width: 550px;
        height: calc(100% - 50px);
        background: #181818;
        padding: 15px;
        position: relative;
        padding: 0 0 50px 0;
        
        h1 {
            margin: 10px 0;
            text-align: center;
            height: 50px;
            color: $green;
        }
    }
}