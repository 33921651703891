$green: #30b665;

.list_wrapper {
    width: calc(100% - 30px);
    height: calc(100% - 85px);
    padding: 0 15px 15px 15px;
    overflow: auto;

    .item {
        width: 100%;
        height: auto;
        min-height: 50px;
        background: #262626;
        color: #FFF;
        margin: 15px 0;
        border-radius: 10px;
        transition: all .3s ease-in-out; 
        position: relative;
        overflow: hidden;
        &:hover {
            @media screen and (min-width: 760px) {
                background: #333;
            }
        }

        p {
            margin: 0;
            line-height: 50px;
            padding: 0 150px 0 15px;
            position: relative;
            @media screen and (max-width: 760px) {
                line-height: 1.6em;
                padding: 15px 15px 0 15px;
            }
        }
        &.completed {
            background: #262626 !important;

            p {
                color: #777;
            }
        }

        .btns {
            width: auto;
            height: 50px;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 50;
            display: flex;
            @media screen and (max-width: 760px) {
                position: relative;
                float: right;
            }
            
            div {
                opacity: .5;
                transition: all .3s ease-in-out; 
                cursor: pointer;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.status_bar {
    width: 100%;
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 200;
    background: #242424;
    position: relative;

    .add_new {
        height: 40px;
        padding: 0 20px;
        background: $green;
        color: #FFF;
        font-weight: bold;
        position: absolute;
        right: 15px;
        bottom: 100%;
        z-index: 50;
        margin: 0 0 15px 0;
        cursor: pointer;
        line-height: 40px;
        border-radius: 14px;
    }

    button {
        font-size: 14px;
        color: #FFF;
        background: #444;
        height: 40px;
        padding: 0 15px;
        border: none;
        outline: none;
        margin: 5px;
        border-radius: 10px;
        font-family: Arial, Helvetica, sans-serif;
        cursor: pointer;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out; 
        &:hover {
            background: #555;
        }

        &.active {
            background: $green;
        }
    }
}

.empty {
    width: 100%;
    height: auto;
    padding: 200px 0;
    text-align: center;
    color: #777;
    line-height: 2em;
}