$green: #30b665;

.form_wrapper {
    width: calc(100% - 30px);
    height: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;

    textarea {
        width: calc(100% - 20px);
        height: auto;
        background: #333;
        border-radius: 10px;
        padding: 10px;
        border: none;
        outline: none;
        font-family: Arial, Helvetica, sans-serif;
        color: #FFF;
    }

    .error {
        display: block;
        color: #ce3030;
        padding: 15px 0;
    }

    button {
        height: 40px;
        padding: 0 15px;
        font-family: Arial, Helvetica, sans-serif;
        border: none;
        outline: none;
        background: $green;
        font-weight: bold;
        color: #FFF;
        margin: 15px 0;
        border-radius: 10px;
        cursor: pointer;
    }

    a {
        height: 40px;
        padding: 0 15px;
        background: #444;
        color: #AAA;
        display: inline-block;
        vertical-align: top;
        margin: 15px;
        line-height: 40px;
        text-decoration: none;
        outline: none;
        border-radius: 10px;
        cursor: pointer;
    }
}
