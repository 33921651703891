.delete_wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 900;

    .delete_shadow {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 20;
        background: rgba(34, 34, 34, .7);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        opacity: 0;
        transition: all .2s ease-in-out; 
    }

    .delete_btns {
        width: calc(100% - 30px);
        height: auto;
        position: absolute;
        z-index: 30;
        left: 15px;
        bottom: -180px;
        transition: all .2s ease-in-out;

        .delete_y_wrapper {
            width: 100%;
            height: auto;
            background: #151515;
            color: #999;
            border-radius: 14px;
            margin-bottom: 10px;
            text-align: center;
            overflow: hidden;

            p {
                line-height: 50px;
                border-bottom: 1px solid #333;
                margin: 0;
            }

            .delete_btn {
                width: 100%;
                height: auto;
                line-height: 50px;
                color: #e73939;
                font-weight: bold;
                cursor: pointer;
                transition: all .3s ease-in-out;
                &:hover {
                    background: #000;
                }
            }
        }

        .delete_cencel {
            width: 100%;
            height: auto;
            background: #151515;
            color: #949494;
            border-radius: 14px;
            line-height: 50px;
            text-align: center;
            font-weight: bold;
            cursor: pointer;
            transition: all .3s ease-in-out;
            &:hover {
                background: #000;
            }
        }
    }

    &.active {
        .delete_shadow {
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            opacity: 1;
        }

        .delete_btns {
            bottom: 15px;
        }
    }
}